<template>
  <div>
    <StudentFAQ />
    <div>
      <h2>How do I check the weather before my flight lesson?</h2>
      <p>
        An important part of flight training, particularly initial training, is taking
        initiative to learn about ancillary (non-flying) aviation subjects, such as
        meteorology. As you progress through your flight training, you will learn more
        about meteorology and its importance to aviation. During your initial training,
        your instructor will obtain meteorological briefings and you can supplement this
        by obtaining meteorological briefings yourself and discussing it with your
        instructor.
      </p>
      <p>
        It is important <i>(and a legal requirement! CAR1988 REG120)</i> that you obtain
        your weather information from an approved Aeronautical Information Service (AIS).
        Within Australia, there is only one approved AIS service called NAIPS, which is
        provided by a government-owned organisation called AirServices Australia
        (airservices).
      </p>
      <p>
        It is also a legal requirement for the pilot in command to obtain a current
        weather report and forecast for any flight that is not within the vicinity of
        the aerodrome <i>(CAR1988 REG239)</i> Vicinity of the aerodrome is defined as
        10 nautical miles from the aerodrome reference point (ARP) e.g. when flying to
        the Archerfield training area. Once you have your pilot licence, this will be you!
      </p>
      <p>
        If you have not used AirServices NAIPS before, you will need to register an
        account and login each time you wish to obtain a meteorological briefing. NAIPS
        can be found on
        <a href="https://www.airservicesaustralia.com/naips/">
        the AirServices Australia website
        </a>.
      </p>
      <p>
        Let's get started by
        <a href="https://www.airservicesaustralia.com/naips/Account/Register">
        registering an AirServices Australia NAIPS account
        </a>.
      </p>
      <p>
        <img class="weather-img" :src="require('@/assets/images/www/naips/registration-800.png')" alt="NAIPS registration"/>
      </p>
      <p>
        Once you have registered an AirServices Australia NAIPS account, you will be able
        to login at any time and obtain a meteorological briefing.
      </p>
      <p>
        <img class="weather-img" :src="require('@/assets/images/www/naips/login-800.png')" alt="NAIPS login"/>
      </p>
      <p>
        There are several types of meteorological briefing, but for your initial training,
        within the circuit or training area, we will obtain a
        <quote>"Location Briefing"</quote>. After logging in, select Location Briefing.
      </p>
      <p>
        On this page you will see several location entries where you can enter one or more
        aerodrome locations from which to obtain a briefing. These aerodrome locations
        take the form of a four-letter ICAO airport code. If you are unsure of the code
        for an aerodrome, you will find the complete list in
        <router-link to="/how-to-find-documentation">the ERSA</router-link> under
        <quote>"Aerodrome and ALA Codes"</quote>. Note that not all aerodromes provide a
        meteorological briefing service.
      </p>
      <p>
        We will start by obtaining a meteorological briefing for:
      </p>
      <div class="list-container">
        <ul class="steps-list">
          <li><b>YBAF</b> Archerfield</li>
          <li><b>YBBN</b> Brisbane</li>
          <li><b>YBCG</b> Gold Coast</li>
          <li><b>YBSU</b> Sunshine Coast</li>
          <li><b>YAMB</b> Amberley</li>
        </ul>
      </div>
      <p>
        We will also select the options for other meteorological information:
      </p>
      <div class="list-container">
        <ul class="steps-list">
          <li><b>NOTAM</b> Notice to Airmen</li>
          <li><b>GAF</b> Graphical Area Forecast</li>
          <li><b>GPWT</b> Grid Point Wind and Temperature Forecast</li>
        </ul>
      </div>
      <p>
        <img class="weather-img" :src="require('@/assets/images/www/naips/location-briefing-800.png')" alt="NAIPS location briefing"/>
      </p>
      <p>
        Next click <b>Submit</b> to obtain your meteorological briefing.
      </p>
      <p>
        Interpreting and understanding meteorological briefings can be quite detailed, but
        give it a go! A useful reference for interpreting briefings is the
        <a href="https://vfrg.flightscopeaviation.com.au/visual-flight-rules-guide/07-chapter2-planning-your-flight.pdf">
        Meteorology section of the Visual Flight Rules Guide
        </a>. A complete and detailed explanation of meteorological briefing information
        is provided in a document called the Aeronautical Information Package (AIP),
        section General 3.5. All of this documentation can be found under
        <router-link to="/student-resources">Student Resources</router-link>.
      </p>
    </div>
  </div>
</template>

<script>
import StudentFAQ from '@/components/StudentFAQ.vue'

export default {
  components: {
    StudentFAQ
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/_presets.scss';

.list-container {
  display: inline-block;
}

.steps-list {
  list-style-type: square;
  text-align: left;
}

.weather-img {
  border: 1px solid black;
}
</style>
